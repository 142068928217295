import axios from "axios";
import jwtDecode from "jwt-decode";

let server = axios.create({});
const BASE_URL = process.env.REACT_APP_BASE_URL;

const setHeaders = (token: string) => {
    server.defaults.headers.common['authorization'] = token;
}

const login = async (googleIdToken: string) => {
    let userDetails = {};
    try {
        const res = await server.post(`${BASE_URL}login/`, { token: googleIdToken })
        sessionStorage.setItem("token", res.data);
        const { username, email, picture }: { username: string, email: string, picture: string } = jwtDecode(res.data);
        userDetails = { username, email, picture, token: res.data };
        setHeaders(res.data);
        return userDetails
    } catch (err) {
        console.log(err);
    }
}

// Transaction APIs

const getTransactions = async (startDate: any, endDate: any) => {
    startDate = startDate?.toISOString();
    endDate = endDate?.toISOString();
    try {
        return await server.get(`${BASE_URL}transaction?startDate=${startDate}&endDate=${endDate}`)
    } catch (err) {
        console.log(err);
    }
}

const editTransaction = async (id: any, transactionDetails: any) => {
    try {
        return await server.patch(`${BASE_URL}transaction/${id}`, { ...transactionDetails })
    } catch (err) {
        console.log(err);
    }
}

const deleteTransaction = async (id: any) => {
    try {
        return await server.delete(`${BASE_URL}transaction/${id}`)
    } catch (err) {
        console.log(err);
    }
}

const deleteTransactions = async (ids: any) => {
    try {
        return await server.post(`${BASE_URL}transaction/delete`, ids);
    } catch (err) {
        console.error(err);
    }
}

const createTransaction = async (transactionDetails: any) => {
    try {
        return await server.post(`${BASE_URL}transaction`, { ...transactionDetails });
    } catch (err) {
        console.log(err);
    }
}

// Category APIs

const getCategories = async () => {
    try {
        return await server.get(`${BASE_URL}category/`)
    } catch (err) {
        console.log(err);
    }
}

const editCategory = async (id: any, categoryDetails: any) => {
    try {
        return await server.patch(`${BASE_URL}category/${id}`, { ...categoryDetails })
    } catch (err) {
        console.log(err);
    }
}

const deleteCategory = async (id: any) => {
    try {
        return await server.delete(`${BASE_URL}category/${id}`)
    } catch (err) {
        console.log(err);
    }
}

const createCategory = async (categoryDetails: any) => {
    try {
        return await server.post(`${BASE_URL}category`, { ...categoryDetails });
    } catch (err) {
        console.log(err);
    }
}

const addTelegramIntegration = async (uuid: any) => {
    try {
        return await server.post(`${BASE_URL}integrations/telegram/add`, { uuid: uuid });
    } catch (err) {
        console.log(err);
    }
}


export { login, setHeaders, getTransactions, editTransaction, deleteTransaction, deleteTransactions, createTransaction, getCategories, editCategory, deleteCategory, createCategory, addTelegramIntegration };