import './analytics.scss';
import { Table, Button, Card, Space, Tag, Input, message, InputNumber, Form, Typography, Popconfirm, DatePicker, Select } from 'antd';
import Navigation from '../../Components/Navigation/navigation';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import type { ColumnType } from 'antd/es/table';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { deleteTransactions, editCategory, editTransaction, getCategories, getTransactions } from "../../Services/services"
import { useState, useRef, useEffect, JSXElementConstructor, ReactElement, ReactFragment, ReactPortal } from 'react';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { InputRef } from 'antd';
import { useDate } from '../../context/dateContext';
import Highlighter from 'react-highlight-words';
import TopNav from '../../Components/Navigation/topNav/topNav';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { TbReceiptRupee, TbCategoryPlus, TbFileTypeCsv } from "react-icons/tb";
import { useAuth } from '../../context/authContext';
import AddTransaction from '../../Components/AddTransaction/addTransaction';
import AddCategory from '../../Components/AddCategory/addCategory';
import { CSVLink } from "react-csv";

dayjs.extend(LocalizedFormat)

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface DataType {
    key: string;
    name: string;
    cost: number;
    date: any;
    category: any;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'date' | 'tags';
    record: any;
    index: number;
    children: React.ReactNode;
}






export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Spending across categories',
        },
    },
};



function Analytics() {

    type DataIndex = keyof DataType;
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [transactions, setTransactions] = useState<any>(null);
    const [categories, setCategories] = useState<any>(null);
    const [categoriesForm, setCategoriesForm] = useState<any>(null);
    const [tableData, setTableData] = useState<any>(null);
    const [columnsData, setColumnsData] = useState<any>(null);
    const searchInput = useRef<InputRef>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [editingKey, setEditingKey] = useState('');
    const { date, setDiffDate } = useDate();
    const { user, login, logout } = useAuth();
    const [openAddTransaction, setOpenAddTransaction] = useState<any>(false);
    const [openAddCategory, setOpenAddCategory] = useState<any>(false);
    const { RangePicker } = DatePicker;
    useEffect(() => {
        if (date?.startDate && date?.endDate) {
            getTransactions(date?.startDate, date?.endDate).then((res: any) => {
                setTransactions(res.data);
            })
            getCategories().then((res: any) => {
                setCategories(res.data);
                const categoriesForm = res.data.map((cat: any) => {
                    return { label: cat.name, value: cat.id }
                })
                setCategoriesForm(categoriesForm)
            })
        }
        setColumnsData(columns);
    }, [date?.startDate, date?.endDate])

    useEffect(() => {
        generateTableData();
    }, [transactions, categories])

    const EditableCell: React.FC<EditableCellProps> = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        let inputNode = <Input />;
        switch (inputType) {
            case 'number':
                inputNode = <InputNumber />
                break;
            case 'date':
                inputNode = <DatePicker showTime />
                break;
            case 'tags':
                inputNode =
                    <Select
                        labelInValue
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select category(ies)"
                        options={categoriesForm}
                    />
                break;
            default:
                inputNode = <Input />
                break;
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const isEditing = (record: DataType) => record.key === editingKey;

    const cancel = () => {
        setEditingKey('');
    };

    const edit = (record: any) => {
        record = { ...record, date: dayjs(record.date) };

        const category = record.category.map((cat: any) => {
            return { label: cat.label, value: cat.value }
        })
        form.setFieldsValue({ ...record, category });
        setEditingKey(record.key);
    };

    const generateTableData = () => {
        let data: { key: any; cost: any; name: any; date: any; category: any[] }[] = [];
        transactions?.map((tr: any) => {
            data.push({
                key: tr.id,
                cost: tr.cost,
                name: tr.name,
                date: tr.created_at,
                category: [...tr?.categories?.map((t: any) => { return { label: t.name, value: t.id } })],
            })
        })
        setTableData(data);
    }

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as any;
            const transaction = transactions.find((tr: any) => key === tr.id);
            let cats = [];
            cats = row.category.map((c: any) => {
                return c.value;
            })
            if (transaction) {
                const newTransaction = {
                    ...transaction,
                    created_at: row.date,
                    name: row.name,
                    cost: parseFloat(row.cost),
                    categories: cats,
                }
                editTransaction(newTransaction.id, newTransaction).then((res: any) => {
                    if (res.status === 201 || res.status === 200) {
                        message.success(`Successfully updated the transaction with name ${res?.data.name}`);
                        getTransactions(date?.startDate, date?.endDate).then((res: any) => {
                            setTransactions(res.data);
                            generateTableData();
                        })
                    } else {
                        message.error(`Unable to update the transaction. Bad Request.`)
                    }
                    setEditingKey('');
                })
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const deleteRows = async () => {
        try {
            const res = await deleteTransactions(selectedRowKeys);
            getTransactions(date?.startDate, date?.endDate).then((res: any) => {
                setTransactions(res.data);
            })
            message.success(`Successfully deleted ${res?.data.count} transactions`)
        } catch (err) {
            message.error("Unable to delete transactions!");
        }
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            return (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }} />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns: any = [
        {
            title: 'Transaction Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            editable: true,
            render: (_: any, record: { cost: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }) => (
                <div>₹ {record.cost}</div>
            ),
            sorter: (a: any, b: any) => a.cost - b.cost,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            editable: true,
            render: (_: any, { date }: any) => (
                dayjs(date).format('lll')
            ),
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => { return Date.parse(a.date) - Date.parse(b.date) },
        },
        {
            title: 'Tags',
            key: 'category',
            dataIndex: 'category',
            editable: true,
            render: (_: any, { category }: any) => (
                <>
                    {category.map((cat: any) => {
                        cat = cat.label;
                        let color = cat.length > 5 ? 'geekblue' : 'green';
                        return (
                            <Tag color={color} key={cat}>
                                {cat.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];

    const addNewTransaction = (transaction: any) => {
        setTransactions([...transactions, transaction]);
    }
    const addNewCategories = (category: any) => {
        setCategories([...categories, category])
    }
    const mergedColumns = columns.map((col: any) => {
        let inputType = 'text';
        switch (col.title) {
            case 'Date':
                inputType = 'date';
                break;
            case 'Tags':
                inputType = 'tags';
                break;
            default:
                inputType = 'text'
        }

        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const setDateChange = (details: any) => {
        console.log(details, "Details")
        setDiffDate({ startDate: details[0], endDate: details[1] })
    }

    return (
        <div className="home">
            {/* <Navigation selectedNav="Analytics" /> */}
            <div className='main-analytics'>
                <TopNav pageName={"Analytics"} />
                <div style={{ margin: '36px 0 16px 16px', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontWeight: '500', color: '#20294C', opacity: 0.8, fontSize: '1.3rem', margin: 'auto 0' }}>
                        {user?.name.split(" ")[0]}, All your transactions.
                    </div>
                    <Card className="addCards" bodyStyle={{ height: "100%", padding: '6px' }} title="" bordered={false}>
                        <div style={{ display: 'flex', gap: '10px', flexDirection: "row" }}>
                            <div style={{ cursor: 'pointer' }}>
                                <div className="buttons py-1 px-3 rounded bg shadow" style={{ fontWeight: '500', display: 'flex', padding: '7px' }}>
                                    <RangePicker format={"DD-MMM-YYYY"} value={[date?.startDate, date?.endDate]} style={{ margin: 'auto' }} onChange={setDateChange} />
                                </div>
                            </div>

                            <div style={{ cursor: 'pointer' }} onClick={() => { setOpenAddTransaction(true) }}>
                                <div className="buttons py-1 px-3 rounded bg shadow" style={{ fontWeight: '500', display: 'flex' }}>
                                    <div style={{ marginTop: '4px' }}>
                                        <TbReceiptRupee style={{ marginRight: '12px' }} />
                                    </div>
                                    <span> </span> Add Transaction
                                </div>
                            </div>
                            {/* <Divider /> */}
                            <div className="buttons py-1 px-3 rounded bg shadow" onClick={() => setOpenAddCategory(true)} style={{ fontWeight: '500', display: 'flex', cursor: "pointer" }}>
                                <div style={{ marginTop: '4px' }}>
                                    <TbCategoryPlus style={{ marginRight: '12px' }} />
                                </div>
                                <span> Add Category</span>
                            </div>
                            <div className="buttons py-1 px-3 rounded bg shadow" style={{ fontWeight: '500', display: 'flex', cursor: "pointer" }}>
                                <div style={{ marginTop: '4px' }}>
                                    <TbFileTypeCsv style={{ marginRight: '12px' }} />
                                </div>
                                {tableData && <CSVLink
                                    filename={"coinkeep_data.csv"}
                                    data={tableData}
                                    className="buttons"
                                    onClick={() => {
                                        message.success("The file is downloading")
                                    }}
                                >
                                    Export
                                </CSVLink>
                                }
                            </div>
                        </div>
                    </Card>
                </div>
                <div style={{ marginTop: "0" }}>
                    <Card bodyStyle={{ padding: "0px" }}
                        title="Transactions"
                        bordered={true}
                        style={{ width: '100%' }}
                        extra={<Button
                            onClick={deleteRows}
                            disabled={selectedRowKeys.length === 0}
                            type="primary"
                            icon={<DeleteOutlined />}
                            danger>
                            Delete
                        </Button>}
                    >
                        <Form form={form} component={false}>
                            <Table components={{
                                body: {
                                    cell: EditableCell,
                                },

                            }}
                                rowSelection={rowSelection} size='small' scroll={{ y: '500px' }} columns={mergedColumns} dataSource={tableData} />
                        </Form>
                    </Card>
                </div>
                <AddTransaction SetOpenAddTransaction={setOpenAddTransaction} setTransactions={addNewTransaction} OpenAddTransaction={openAddTransaction} />
                <AddCategory SetOpenAddCategory={setOpenAddCategory} setCategories={addNewCategories} OpenAddCategory={openAddCategory} />
            </div>
        </div>
    );
}

export default Analytics;
