import jwtDecode from 'jwt-decode';
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

export interface User {
    name: string;
    email: string;
    authToken?: string;
    picture?: string;
}

interface AuthContextType {
    user: User | null;
    login: (userData: { username: string, email: string, picture: string, token: string }) => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthContextProviderProps {
    children: ReactNode;
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const [user, setUser] = useState<User | null>(null);
    useEffect(() => {
        try {
            const tokenKey = localStorage.getItem('token');
            if (tokenKey) {
                const { username, email, picture }: { username: string, email: string, picture: string } = jwtDecode(tokenKey)
                setUser({ name: username, email, picture })
            }
        } catch (err) {
            setUser(null)
        }
    }, [])

    const login = (userData: { username: string, email: string, picture: string, token: string }) => {
        localStorage.setItem("token", userData?.token);
        setUser({ name: userData.username, email: userData.email, picture: userData.picture });
    };

    const logout = () => {
        localStorage.setItem("token", "");
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;

// Custom hook to simplify accessing the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthContextProvider');
    }
    return context;
};