import { Form, Input, InputNumber, Modal, Select, message } from 'antd';
import './addTransaction.scss';
import type { SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { createTransaction, getCategories } from '../../Services/services';
import { useForm } from 'antd/es/form/Form';


function AddTransaction({ SetOpenAddTransaction, OpenAddTransaction, setTransactions }: { SetOpenAddTransaction: any, OpenAddTransaction: any, setTransactions: any }) {

    const [form] = useForm();
    const [categories, setCategories] = useState<[]>([]);

    useEffect(() => {
        getCategories().then((res) => {
            const categories = res?.data;
            const categoriesForm = categories.map((cat: any) => {
                return { label: cat.name, value: cat.id }
            })
            setCategories(categoriesForm)
        })
    }, [OpenAddTransaction])

    const handleSubmit = () => {
        const { category, cost, name, type } = form.getFieldsValue();
        createTransaction({ categories: category, cost, name, transactionType: type }).then((res: any) => {
            setTransactions(res.data)
            message.success(`Successfully created transaction ${res?.data?.name}`);
        }).catch((err: any) => {
            message.error(`Unable to create a transaction ${name}`);
        })
        SetOpenAddTransaction(false);
    }

    return (
        <div className="title-home">
            <Modal open={OpenAddTransaction} title="Add Transaction" onCancel={() => SetOpenAddTransaction(false)} onOk={handleSubmit}>
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600, padding: "18px 24px 8px 24px" }}
                    initialValues={{ remember: false }}
                    form={form}
                    onFinish={handleSubmit}
                    layout='horizontal'
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        style={{ textAlign: 'start' }}
                        rules={[{ required: true, message: 'Please input the transaction name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Cost"
                        name="cost"
                        rules={[{ required: true, message: 'Please input the cost of transaction!' }]}
                    >
                        <InputNumber prefix="₹" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                        <Select
                            placeholder="Select transaction type"
                            allowClear
                            options={
                                [{ label: "Outgoing", value: "OUTGOING" },
                                { label: "Incoming", value: "INCOMING" }
                                ]
                            }
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Category"
                        name="category"

                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select category(ies)"
                            defaultValue={[]}
                            options={categories}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default AddTransaction;
