import Login from './Pages/Auth/login';
import Home from './Pages/Home/home';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import Analytics from './Pages/Analytics/analytics';
import Integrations from './Pages/Bots/bots';
import { ProtectedRoute } from './protectedRoute';

const routes =
    [
        {
            path: '/',
            element: <ProtectedRoute><Home /></ProtectedRoute>,
            errorElement: <ErrorPage />
        },
        {
            path: '/home',
            element: <ProtectedRoute><Home /></ProtectedRoute>,
            errorElement: <ErrorPage />
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/analytics',
            element: <ProtectedRoute><Analytics /></ProtectedRoute>
        },
        {
            path: '/integrations',
            element: <ProtectedRoute><Integrations /></ProtectedRoute>
        }
    ]

export default routes;