import "./login.scss";
import { useEffect, useRef, useState } from "react";
import { Card, Space } from 'antd';
import GoogleAuth from "../../Components/GoogleAuth/GoogleAuth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";

function Login() {
  const { user, login, logout } = useAuth();
  const navigate = useNavigate();
  const loginUser = (user: any) => {
    login(user);
    if (user && user.email) {
      navigate('/home')
    }
  }

  return (
    <div className="login">
      <Card style={{ textAlign: "center", display: "grid" }}>
        <div>
          <h1>Welcome to <span style={{ color: "#FF0000" }}>Coin</span><span>Keep</span></h1>
        </div>
        <div style={{ padding: "0 96px 24px 96px", fontSize: "1rem" }}>
          CoinKeep is a personal finance management app that helps you track your income, expenses, and budget. With CoinKeep, you can easily see where your money is going and make adjustments to your spending habits. Here are some of the features of CoinKeeper:

          Track your income and expenses,
          Create budgets,
          Set financial goals and
          View reports on your spending habits. In short CoinKeep helps to keep your coins.
        </div>
        <div style={{ padding: "12px", fontWeight: 500, fontSize: "1rem " }}>
          Login now to get started!
        </div>
        <div style={{ margin: 'auto' }}>
          <GoogleAuth setUser={loginUser} />
        </div>
      </Card>

    </div>
  );
}

export default Login;
