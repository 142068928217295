import './topNav.scss';
import { Avatar, Dropdown, Form } from 'antd';
import type { MenuProps } from 'antd';
import { useAuth } from "../../../context/authContext";
import { useDate } from '../../../context/dateContext';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";
import { TbReceipt, TbSmartHome, TbRobotFace } from "react-icons/tb";


function TopNav({ pageName }: { pageName: any }) {
    const { user, logout } = useAuth();
    const { date, setDiffDate } = useDate();
    const [form] = Form.useForm();
    let location = useLocation();
    const pathname = location.pathname;
    console.log(pathname, "Hello")
    useEffect(() => {
        form.setFieldsValue({ date: [date?.startDate, date?.endDate] })
    }, [date])

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" onClick={logout}>
                    Logout
                </a>
            ),
        },
    ];

    return (
        <div className="title-home">
            <div style={{ margin: 'auto 0' }}>
                <div className="title-name">
                    Coin<span style={{ color: 'red' }}>Keep</span>
                </div>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
                <div className='nav-buttons'>
                    <div style={{ margin: 'auto 0' }}>

                        <Link to={'/home'} className={['/', '/home'].includes(pathname) ? 'nav-button-selected py-1 px-3 rounded bg shadow' : 'nav-button py-1 px-3 rounded bg shadow'} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'row', gap: '6px' }}>
                            <div style={{ fontSize: '1rem' }}><TbSmartHome style={{ paddingTop: '3px' }} /></div>
                            <div>HOME</div></Link>
                    </div>
                    <div style={{ margin: 'auto 0' }}>
                        <Link to={'/analytics'} className={['/transactions', '/analytics'].includes(pathname) ? 'nav-button-selected py-1 px-3 rounded bg shadow' : 'nav-button py-1 px-3 rounded bg shadow'} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'row', gap: '6px' }}>
                            <div style={{ fontSize: '1rem' }}><TbReceipt style={{ paddingTop: '3px' }} /></div>
                            <div>TRANSACTIONS</div>
                        </Link>
                    </div>
                    <div style={{ margin: 'auto 0' }}>
                        <Link to={'/integrations'} className={['/integrations'].includes(pathname) ? 'nav-button-selected py-1 px-3 rounded bg shadow' : 'nav-button py-1 px-3 rounded bg shadow'} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'row', gap: '6px' }}>
                            <div style={{ fontSize: '1rem' }}><TbRobotFace style={{ paddingTop: '3px' }} /></div>
                            <div>INTEGRATIONS</div>
                        </Link>
                    </div>
                </div>
                <div className="Login nav-buttons">
                    <Dropdown menu={{ items }} placement="bottomLeft">
                        <div style={{ display: 'flex' }}>
                            <Avatar src={user?.picture} style={{ verticalAlign: 'middle' }} size="large" />
                            <div style={{ margin: 'auto', paddingLeft: "12px" }}>{user?.name}</div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default TopNav;
