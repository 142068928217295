import './bots.scss';
import Navigation from '../../Components/Navigation/navigation';
import TopNav from '../../Components/Navigation/topNav/topNav';
import { Button, Card, Form, Input } from 'antd';
import { addTelegramIntegration } from '../../Services/services';
import { useAuth } from '../../context/authContext';
import { FaSave } from "react-icons/fa";

function Integrations() {
    const [form] = Form.useForm();
    const { user, login, logout } = useAuth();
    const saveTelegram = () => {
        const response = addTelegramIntegration(form.getFieldValue("telegram"));
        // console.log(response);
    }
    return (
        <div className="App">
            {/* <Navigation selectedNav="Bots" /> */}
            <div className="main-home">
                <TopNav pageName={"Integrations"} />
                <div style={{ margin: '36px 0 16px 16px', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontWeight: '500', color: '#20294C', opacity: 0.8, fontSize: '1.3rem', margin: 'auto 0' }}>
                        {user?.name.split(" ")[0]}, Add your integrations here, more coming soon.
                    </div>
                    <Card className="addCards" bodyStyle={{ height: "100%", padding: '6px' }} title="" bordered={false}>
                        <div style={{ display: 'flex', gap: '10px', flexDirection: "row" }}>
                            <div style={{ cursor: 'pointer' }} onClick={saveTelegram}>
                                <div className="buttons py-1 px-3 rounded bg shadow" style={{ fontWeight: '500', display: 'flex' }}>
                                    <div style={{ marginTop: '4px' }}>
                                        <FaSave style={{ marginRight: '12px' }} />
                                    </div>
                                    <span> </span> Save
                                </div>
                            </div>
                        </div>
                    </Card>

                </div>
                <div>
                    <Card>
                        <Form
                            layout={'vertical'}
                            form={form}
                        >
                            <Form.Item name="telegram" label="Telegram">
                                <Input style={{ width: '40%' }} placeholder="Enter UUID" />
                            </Form.Item>
                            {/* <Form.Item>
                                <Button onClick={saveTelegram} type="primary">Save</Button>
                            </Form.Item> */}
                        </Form>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Integrations;
