import { useEffect, useRef } from "react";
import jwtDecode from "jwt-decode";
import { login } from "../../Services/services";
function GoogleAuth(props) {
  const googleButton = useRef(null);
  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) return resolve();
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
    });

  const handleGoogleCallback = async (response) => {
    const data = await login(response.credential);
    props.setUser(data);
  };

  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    const id = process.env.REACT_APP_GOOGLE_AUTH_ID;

    loadScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: id,
          callback: handleGoogleCallback,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: "filled_black",
          type: "standard",
          shape: "pill",
          text: "signin",
          size: "large",
        });
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);
  return <div ref={googleButton}></div>;
}

export default GoogleAuth;
