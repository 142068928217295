import React from 'react'
import { useAuth } from "./context/authContext";
import { Navigate, Route } from 'react-router-dom'
import jwtDecode from 'jwt-decode';
import { setHeaders } from './Services/services';


export const ProtectedRoute = ({ children }: { children: any }) => {
    const { user, login } = useAuth();
    const tokenKey = localStorage.getItem('token');
    let username: string = '';
    let email: string = '';
    try {
        if (tokenKey) {
            setHeaders(tokenKey);
            const data: any = jwtDecode(tokenKey)
            if(data){ 
                username = data.username;
                email = data.email;    
            }
        }
        if (!username || !email) {
            return <Navigate to="/login" />;
        }    
    } catch(err) {
        return <Navigate to="/login" />;
    }

    return children;
};


