import './doughnutWidget.scss';
import { useEffect, useState } from 'react';
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,);


function DoughnutWidget({ transactions, categories }: { transactions: any, categories: any }) {


    const [donutData, setDonutData] = useState<any>({
        labels: [],
        datasets: [
            {
                labels: [],
                datasets: []
            },
        ],
    });

    useEffect(() => {
        if (categories && transactions) {
            let outgoingCategories = new Set();
            transactions.forEach((tr: any) => {
                if (tr.transactionType === "OUTGOING") {
                    tr.categories.forEach((cat: any) => {
                        if (cat.name != "Investment")
                            outgoingCategories.add(cat.id);
                    })
                }
            })
            const values = Array.from(outgoingCategories)
            let cats = categories.filter((cat: any) => values.find((val: any) => val == cat.id))
            console.log(cats, "Hello")
            const data = {
                labels: [...values.map((val: any) => cats.find((c: any) => c.id == val).name)],
                datasets: [
                    {
                        label: '₹',
                        data: values?.map((cat: any) => {
                            let currentCat = 0;
                            transactions?.map((tr: any) => {
                                if (tr.categories.find((tra: any) => tra.id === cat) && tr.transactionType != "INCOMING") {
                                    currentCat += tr.cost;
                                }
                            })
                            return currentCat;
                        }),
                        backgroundColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            setDonutData(data);
        }
    }, [transactions, categories])

    return (
        <div>
            <Doughnut width={50} data={donutData} />
        </div>
    );
}

export default DoughnutWidget;
