import './addCategory.scss';
import { Form, Input, InputNumber, Modal, Select, message } from 'antd';
import { createCategory } from '../../Services/services';


function AddCategory({ SetOpenAddCategory, OpenAddCategory, setCategories }: { SetOpenAddCategory: any, OpenAddCategory: any, setCategories: any }) {

    const [form] = Form.useForm();
    const handleSubmit = async () => {
        const { name } = form.getFieldsValue();
        try {
            const res: any = await createCategory({ name });
            console.info(res.data)
            setCategories(res.data)
            message.success(`Successfully created category ${res.data.name}`)

        } catch (err) {
            message.error(`Unable to create category due to ${err}`);
        }
        SetOpenAddCategory(false);
    }

    return (
        <div className="title-home">
            <Modal open={OpenAddCategory} title="Add Category" onCancel={() => SetOpenAddCategory(false)} onOk={() => handleSubmit()}>
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600, padding: '24px 24px 8px 24px' }}
                    initialValues={{ remember: false }}
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input the transaction name!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default AddCategory;
