import './multiBarWidget.scss';
import { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BACKGROUND_COLORS: string[] = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
]

function MultiBarWidget({ transactions, categories, date }: { transactions: any, categories: any, date: any }) {

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Average spend on each category per day',
            },
        },
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            },
        },
    };

    const [barChartData, setBarChartData] = useState<any>({
        labels: [],
        datasets: [
            {
                labels: [],
                datasets: []
            },
        ],
    });

    useEffect(() => {
        if (categories && transactions) {
            const a = calculateAverageAmountPerDay({ transactions, categories });
            const labels: any[] = [];
            const dataValues: any[] = [];
            Object.keys(a).forEach((c: any) => {
                labels.push(c);
                dataValues.push(a[c]);
            })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Average Spend",
                        data: dataValues,
                        backgroundColor: BACKGROUND_COLORS,
                    }]

            };
            setBarChartData(data);
        }
    }, [transactions, categories])

    function calculateAverageAmountPerDay({ transactions, categories }: { transactions: any, categories: any }) {
        const categoryAmountPerDay: any = {};
        transactions = transactions.filter((tr: any) => tr.transactionType === "OUTGOING")
        transactions.forEach((transaction: any) => {
            const transactionDate = new Date(transaction.created_at);
            const transactionDay = transactionDate.getDate();
            transaction.categories.forEach((category: any) => {
                if (category.name != "Investment") {
                    const categoryIndex = categories.findIndex((cat: any) => cat.id === category.id);
                    if (categoryIndex !== -1) {
                        const categoryName: string = categories[categoryIndex].name;
                        if (!categoryAmountPerDay[categoryName]) {
                            categoryAmountPerDay[categoryName] = 1;
                            categoryAmountPerDay[categoryName] = Array(31).fill(0);
                        }
                        categoryAmountPerDay[categoryName][transactionDay - 1] += transaction.cost;
                    }
                }
            });
        });
        const averageAmountPerDay: any = {};
        Object.keys(categoryAmountPerDay).forEach(category => {
            const totalAmount = categoryAmountPerDay[category].reduce((acc: any, val: any) => acc + val, 0);
            const daysInMonth = date?.endDate.diff(date.startDate, 'days') + 1;
            averageAmountPerDay[category] = totalAmount / daysInMonth;
        });

        return averageAmountPerDay;
    }

    return (
        <div>
            <Bar options={options} data={barChartData} />
        </div>
    );
}

export default MultiBarWidget;
