import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import dayjs from 'dayjs'

export interface Date {
    startDate: any;
    endDate: any;
}

interface DateContextType {
    date: Date | null;
    setDiffDate: (date: Date) => void;
}

export const DateContext = createContext<DateContextType | undefined>(undefined);

interface DateContextProviderProps {
    children: ReactNode;
}

const DateContextProvider = ({ children }: DateContextProviderProps) => {
    const [date, setDate] = useState<Date | null>(null);
    useEffect(() => {
        try {
            const startDate_def = dayjs().startOf('month');
            const endDate_def = dayjs().endOf('month')
            setDate({ startDate: startDate_def, endDate: endDate_def })
        } catch (err) {
            setDate(null)
        }
    }, [])

    const setDiffDate = (date: any) => {
        setDate(date);
    };


    return (
        <DateContext.Provider value={{ date, setDiffDate }}>
            {children}
        </DateContext.Provider>
    );
};

export default DateContextProvider;

export const useDate = () => {
    const context = useContext(DateContext);
    if (!context) {
        throw new Error('useDate must be used within an DateContextProvider');
    }
    return context;
};