import './home.scss';
import { TiFlowSwitch } from "@react-icons/all-files/ti/TiFlowSwitch";
import { TbReceiptRupee, TbCategoryPlus, TbMathAvg } from "react-icons/tb";
import { Card, Statistic, Divider, DatePicker, Row, Col } from 'antd';
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from "chart.js";
import { getCategories, getTransactions } from "../../Services/services"
import TopNav from '../../Components/Navigation/topNav/topNav';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import AddTransaction from '../../Components/AddTransaction/addTransaction';
import AddCategory from '../../Components/AddCategory/addCategory';
import DoughnutWidget from '../../Components/DougnutWidget/doughnutWidget';
import MultiBarWidget from '../../Components/MultiBarWidget/multiBarWidget';
import { useDate } from '../../context/dateContext';
import { useAuth } from '../../context/authContext';
import dayjs from 'dayjs';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,);



export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Month wise expenses',
        },
    },
};
function Home() {
    const [transactions, setTransactions] = useState<any>(null);
    const [categories, setCategories] = useState<any>(null);
    const [income, setIncome] = useState<number>(0);
    const { user, login, logout } = useAuth();
    const [spending, setSpending] = useState<number>(0);
    const [openAddTransaction, setOpenAddTransaction] = useState<any>(false);
    const [openAddCategory, setOpenAddCategory] = useState<any>(false);
    const [month, setMonth] = useState<any>();
    const [investment, setInvestment] = useState<number>(0);
    const [last6monTransactions, setLast6monTransactions] = useState<any>();
    const [avgSpending, setAvgSpending] = useState<any>();
    const [avgInvestment, setAvgInvestment] = useState<any>();
    const [avgSavings, setAvgSavings] = useState<any>();

    useEffect(() => {
        if (month?.startDate && month?.endDate) {
            getTransactions(month?.startDate, month?.endDate).then((res: any) => {
                setTransactions(res.data);
            })
            getCategories().then((res: any) => {
                setCategories(res.data);
            })
        }
    }, [month?.startDate, month?.endDate])

    useEffect(() => {
        setMonth({ startDate: dayjs().startOf('month'), endDate: dayjs().endOf('month') });
        getTransactions(dayjs().subtract(6, 'month').startOf('month'), dayjs().endOf('month')).then((res: any) => {
            setLast6monTransactions(res.data);
        });
    }, [])

    useEffect(() => {
        let spending = 0;
        let investment = 0;
        let saving = 0;
        let income = 0;
        let uniqueMonths = new Set();
        last6monTransactions?.map((tr: any) => {
            uniqueMonths.add(dayjs(tr.created_at).month());
            if (tr?.transactionType === "INCOMING") income += tr.cost;
            if (tr?.categories.map((cat: any) => cat.name).includes("Investment")) investment += tr.cost;
            else spending += tr.cost;
        })
        saving = (income - investment - saving) / uniqueMonths.size;
        investment = investment / uniqueMonths.size;
        spending = spending / uniqueMonths.size;
        setAvgInvestment(investment);
        setAvgSavings(saving);
        setAvgSpending(spending);
    }, [last6monTransactions])

    useEffect(() => {
        let income = 0;
        let spending = 0;
        let investment = 0;
        console.log(transactions, "Printing here")
        transactions?.map((tr: any) => {
            if (tr?.transactionType === "INCOMING") income += tr.cost;
            else if (tr?.categories.map((cat: any) => cat.name).includes("Investment")) investment += tr.cost;
            else spending += tr.cost;
        })
        setIncome(income);
        setSpending(spending);
        setInvestment(investment);
    }, [transactions])

    const addNewTransaction = (transaction: any) => {
        setTransactions([...transactions, transaction]);
    }
    const addNewCategories = (category: any) => {
        setCategories([...categories, category])
    }

    const onMonthChange = (value: any) => {
        setMonth({ startDate: dayjs(value).startOf('month'), endDate: dayjs(value).endOf('month') });
    }

    return (
        <div className="home">
            <div className="main-home">
                <TopNav pageName={"Dashboard"} />
                <div style={{ margin: '36px 0 16px 16px', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontWeight: '500', color: '#20294C', opacity: 0.8, fontSize: '1.3rem', margin: 'auto 0' }}>
                        Hi, {user?.name.split(" ")[0]}
                    </div>
                    <Card className="addCards" bodyStyle={{ height: "100%", padding: '6px' }} title="" bordered={false}>
                        <div style={{ display: 'flex', gap: '10px', flexDirection: "row" }}>
                            <div style={{ cursor: 'pointer' }} onClick={() => { setOpenAddTransaction(true) }}>
                                <div className="buttons py-1 px-3 rounded bg shadow" style={{ fontWeight: '500', display: 'flex' }}>
                                    <div style={{ marginTop: '4px' }}>
                                        <TbReceiptRupee style={{ marginRight: '12px' }} />
                                    </div>
                                    <span> </span> Add Transaction
                                </div>
                            </div>
                            {/* <Divider /> */}
                            <div className="buttons py-1 px-3 rounded bg shadow" onClick={() => setOpenAddCategory(true)} style={{ fontWeight: '500', display: 'flex', cursor: "pointer" }}>
                                <div style={{ marginTop: '4px' }}>
                                    <TbCategoryPlus style={{ marginRight: '12px' }} />
                                </div>
                                <span> Add Category</span>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="body-home" style={{ display: "flex", margin: '0 0 36px 0', gap: '24px' }}>
                    <Card bordered={false} style={{ width: "72%", alignItems: "right" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: 500, color: '#676B89', margin: 'auto 0', fontSize: '1.1rem', display: 'flex' }}>
                                <div style={{ paddingTop: '2px', paddingRight: '4px' }}>
                                    <TiFlowSwitch />
                                </div>
                                CASH FLOW
                            </div>
                            <div>
                                <DatePicker format={'MMM-YYYY'} defaultValue={dayjs()} onChange={onMonthChange} picker="month" />
                            </div>
                        </div>
                        <Row style={{ paddingTop: '36px', display: 'flex', gap: '24px' }}>
                            <Col span={4}>
                                <Statistic className='money' prefix={<div style={{ color: '#20294C', opacity: 0.8 }}>₹</div>} value={income} precision={2} formatter={(value: any) => <CountUp style={{ color: '#20294C', opacity: 0.8 }} end={value} decimals={2} separator="," />} />
                                <div style={{ fontWeight: 500, fontSize: '1.2rem', color: '#676B89' }}>
                                    Incoming
                                </div>
                            </Col>
                            <Divider style={{ height: '100%' }} type="vertical" />
                            <Col span={4}>
                                <Statistic className='money' prefix={<div style={{ color: "red" }}>₹</div>} value={spending} precision={2} formatter={(value: any) => <CountUp style={{ color: "red" }} end={value} decimals={2} separator="," />} />
                                <div style={{ fontWeight: 500, fontSize: '1.2rem', color: '#676B89' }}>
                                    Outgoing
                                </div>
                                <div className="percentage">
                                    <Statistic className="percentage" valueStyle={{ fontSize: '0.75rem' }} suffix="% of total income" value={((spending) / (income)) * 100} precision={2} />
                                </div>
                            </Col>
                            <Divider style={{ height: '100%' }} type="vertical" />
                            <Col span={4}>
                                <Statistic className='money' prefix={<div style={{ color: "darkgreen" }}>₹</div>} value={investment} precision={2} formatter={(value: any) => <CountUp style={{ color: "darkgreen" }} end={value} decimals={2} separator="," />} />
                                <div style={{ fontWeight: 500, fontSize: '1.2rem', color: '#676B89' }}>
                                    Invested
                                </div>
                                <div className="percentage">
                                    <Statistic className="percentage" valueStyle={{ fontSize: '0.75rem' }} suffix="% of total income" value={((investment) / (income)) * 100} precision={2} />
                                </div>
                            </Col>
                            <Divider style={{ height: '100%' }} type="vertical" />
                            <Col span={4}>
                                <Statistic className='money' prefix={<div style={{ color: '#20294C', opacity: 0.8 }}>₹</div>} value={income - spending - investment} precision={2} formatter={(value: any) => <CountUp style={{ color: '#20294C', opacity: 0.8 }} end={value} decimals={2} separator="," />} />
                                <div style={{ fontWeight: 500, fontSize: '1.2rem', color: '#676B89' }}>
                                    Remaining
                                </div>
                                <div className="percentage">
                                    <Statistic className="percentage" valueStyle={{ fontSize: '0.75rem' }} suffix="% of total income" value={((income - spending - investment) / (income)) * 100} precision={2} />
                                </div>
                            </Col>
                        </Row>

                    </Card>
                    <Card bordered={false} style={{ width: "28%", alignItems: "right" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#676B89' }}>
                            <div style={{ fontWeight: 500, color: '#676B89', margin: 'auto 0', fontSize: '1.1rem', display: 'flex' }}>
                                <div style={{ paddingTop: '2px', paddingRight: '4px' }}>
                                    <TbMathAvg />
                                </div>
                                MONTHLY AVG.
                            </div>
                            <div style={{ fontSize: '0.8rem', fontWeight: '500', margin: 'auto 0', paddingRight: '18px' }}> - LAST 6 MONTHS</div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2px 18px' }}>
                                <div >
                                    <Statistic className='money-2' prefix={<div style={{ color: '#20294C', opacity: 0.8 }}>- ₹</div>} value={avgSpending} precision={2} formatter={(value: any) => <CountUp style={{ color: '#20294C', opacity: 0.8 }} end={value} decimals={2} separator="," />} />
                                </div>
                                <div style={{ margin: 'auto 0', fontWeight: 500, fontSize: '0.8rem', textAlign: 'right', color: '#676B89', opacity: '0.8' }}>
                                    OUTGOING
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2px 18px' }}>
                                <div>
                                    <Statistic className='money-2' prefix={<div style={{ color: '#20294C', opacity: 0.8 }}>+ ₹</div>} value={avgInvestment} precision={2} formatter={(value: any) => <CountUp style={{ color: '#20294C', opacity: 0.8 }} end={value} decimals={2} separator="," />} />
                                </div>
                                <div style={{ margin: 'auto 0', fontWeight: 500, fontSize: '0.8rem', textAlign: 'right', color: '#676B89', opacity: '0.8' }}>
                                    INVESTED
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2px 18px' }}>
                                <div >
                                    <Statistic className='money-2' prefix={<div style={{ color: '#20294C', opacity: 0.8 }}>+ ₹</div>} value={avgSavings} precision={2} formatter={(value: any) => <CountUp style={{ color: '#20294C', opacity: 0.8 }} end={value} decimals={2} separator="," />} />
                                </div>
                                <div style={{ margin: 'auto 0', fontWeight: 500, fontSize: '0.8rem', textAlign: 'right', color: '#676B89', opacity: '0.8' }}>
                                    LEFT
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div style={{ display: "flex", gap: '24px' }}>
                    <Card style={{ width: '35%' }}>
                        <DoughnutWidget transactions={transactions} categories={categories} />
                    </Card>
                    <Card style={{ width: '65%' }}>
                        <MultiBarWidget transactions={transactions} categories={categories} date={month}></MultiBarWidget>
                    </Card>

                </div>
                <AddTransaction SetOpenAddTransaction={setOpenAddTransaction} setTransactions={addNewTransaction} OpenAddTransaction={openAddTransaction} />
                <AddCategory SetOpenAddCategory={setOpenAddCategory} setCategories={addNewCategories} OpenAddCategory={openAddCategory} />
            </div>
        </div>
    );
}

export default Home;
