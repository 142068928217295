import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import routes from './routes';
import AuthContextProvider from './context/authContext';
import DateContextProvider from './context/dateContext';
import { ConfigProvider } from "antd";
// require('dotenv').config()

const router = createBrowserRouter(routes)
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <DateContextProvider>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "GT Walsheim Pro"
            }
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </DateContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
